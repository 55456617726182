<template>
  <div class="FaqClientWrapper" :class="$store.state.user.isSP && 'mobile'">
    <Loading :isLoading="isLoading">
      <FaqClientPage v-if="componentType === 'pc'" ref="faqClient" />
      <FaqClientWindow v-if="componentType === 'sp'" ref="faqClient" />
      <FaqClientMultiple v-if="componentType === 'both'" ref="faqClient" />
      <Modal
        v-if="withInitialForm"
        :click="false"
        :animation="false"
        :show="!$store.state.user.Id"
      >
        <InitialForm @postInitialForm="postInitialForm" />
      </Modal>
      <Modal
        v-if="withEnquete"
        :click="false"
        :animation="true"
        :show="$store.state.enquete.show"
        @closeModal="resetEnquete"
      >
        <Enquete
          :type="$store.state.enquete.type"
          :action="$store.state.enquete.action"
          :item="$store.state.enquete.item"
          @resetEnquete="resetEnquete"
        />
      </Modal>
    </Loading>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientWrapperMixin from '@/mixins/FaqClientWrapperMixin';
import { Watch } from 'vue-property-decorator';
import { eventBus } from '@/eventBus';
import isEqual from 'lodash/isEqual';
import { fromRoute, toRoute } from '@/common/resourceNavigationUtil';
import { extractQueryParams } from '@/common/uriUtil';
import dataResource from '@/common/dataResource';

@Component({})
export default class FaqClientWrapper extends mixins(FaqClientWrapperMixin) {
  created() {
    if (!this.$store.state.constObj.ROUTING) {
      return;
    }
    this.$router.afterEach((route, from) => {
      this.$store.dispatch('history/add', route.params);
    });
    if (this.isHashRouting) {
      window.addEventListener(
        'hashchange',
        e => {
          if (!this.$store.state.user.isIE) {
            return;
          }
          this.cuntomHashRouting();
        },
        false
      );
    }
  }

  // Codes with @Watch because there happens conflicts with the old version if they are in mixins
  @Watch('$route.params', { immediate: true })
  async onRouteParamsChanged(params, oldParams) {
    if (!this.$store.state.constObj.ROUTING) {
      this.autoScroll();
      return;
    }
    if (isEqual(this.nextRouteParams, params)) {
      console.log('cancel update params');
      return;
    }
    console.log(
      "@Watch('$route.params')",
      params,
      oldParams,
      this.nextRouteParams
    );
    const route = await fromRoute(params);

    if (route.talkScript) {
      const routes = [];
      for (let current = route.talkScript; current; current = current.parent) {
        if (current.viewType === 'scenario') {
          const scenario = await dataResource.getItem(current);
          Object.assign(current.items, scenario.items);
        }
        routes.unshift(current);
      }
      routes.push.apply(routes, route.scenarios);
      this.$store.commit('navigation/setRoutes', routes);
    }

    if (this.$store.state.constObj.VARIABLE_META_DATA) {
      this.updateMetaData();
    }
    if (this.isReady && this.isHashRouting) {
      this.issueInitialTicket();
      if (
        !this.$store.state.user.isSP &&
        (params.talkScriptId === '#' || params.talkScriptId === undefined)
      ) {
        setTimeout(() => {
          eventBus.$emit('scrollToTop');
        }, 0);
      }
    }
    this.autoScroll();
  }
  // Codes with @Watch because there happens conflicts with the old version if they are in mixins
  @Watch('$store.state.navigation.routes')
  @Watch('$store.state.navigation.index')
  onNavigationChanged(state, oldState) {
    if (!this.$store.state.constObj.ROUTING) {
      this.autoScroll();
      return;
    }
    const params = toRoute(
      this.$store.getters['navigation/getEfficientRoutes']
    );
    this.nextRouteParams = params;
    if (isEqual(this.$route.params, params)) {
      console.log('cancel route update');
      return;
    }

    const route = { params };
    const query = extractQueryParams(document.location.search);
    if (this.isHistoryRouting) {
      console.log("@Watch('$store.state.navigation')", query);
      delete query._ref;
      route.query = query;
    }
    console.log(
      "@Watch('$store.state.navigation')",
      this.$route.params,
      params,
      route.query || ''
    );
    //  Remove the 'code' parameter
    if (query.code && route.params.talkScriptId) {
      if (this.isHashRouting) {
        // Get the current URL search parameters
        const searchParams = new URLSearchParams(window.location.search);
        // Remove the 'code' parameter
        searchParams.delete('code');
        // create new url
        const newUrl = `${window.location.protocol}//${window.location.host}${
          window.location.pathname
        }${
          searchParams.toString().length ? `?${searchParams.toString()}` : ''
        }`;
        window.history.replaceState(null, '', newUrl);
      } else {
        route.query.code = undefined;
      }
    }
    this.$router.push(route);

    if (this.$store.state.constObj.VARIABLE_META_DATA) {
      this.updateMetaData();
    }
    this.autoScroll();
  }
  // Codes with @Watch because there happens conflicts with the old version if they are in mixins
  @Watch('isReady')
  async initialActions() {
    if (!this.$store.state.constObj.ROUTING) {
      return;
    }
    if (this.$store.state.constObj.CHECK_INITIAL_INPUT) {
      await this.invokeInitialInputData();
      this.existInput && (await this.setTagSearchShow(true));
      await this.setCheckedUrlParams(true);
    }
    if (
      this.$store.state.constObj.WITH_INITIAL_FORM &&
      !this.$store.state.user.Id
    ) {
      return;
    }
    await this.issueInitialTicket();
  }
}
</script>
