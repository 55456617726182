import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    type: String,
    action: String,
    item: Object,
  },
})
export default class EnqueteMixin extends Vue {
  // データ
  enquete = [];
  values = {};
  // フラグ
  invalidValues = false;
  done = false;
  singleEnquete = false;
  // 文言
  heading = '';
  description = '';
  message = '';
  sendText = this.$store.state.constObj.ENQUETE_SEND_TEXT;
  closeText = this.$store.state.constObj.ENQUETE_CLOSE_TEXT;
  alertMessage = this.$store.state.constObj.ENQUETE_ALERT_MESSAGE;
  enqueteRequiredMarkText = this.$store.state.constObj
    .ENQUETE_REQUIRED_MARK_TEXT;
  // その他
  prefix = 'sai_en_';

  height() {
    return this.$store.state.user.isSP && window.innerHeight
      ? `${window.innerHeight - 40}px`
      : 'calc(100vh - 40px)';
  }

  // ラジオボタン、チェックボックの属性値の設定
  getChoiceId(itemIdx, choiceIdx) {
    return this.prefix + itemIdx + '_' + (choiceIdx + 1);
  }
  getChoiceName(itemIdx) {
    return this.prefix + itemIdx;
  }

  // ラジオボタン、チェックボックの値の更新
  updateSelectedChoices(checked, index, type, value, label) {
    switch (type) {
      case 'radio':
        this.values[`${index}`].value = {
          value: value,
          label: label,
        };
        return;
      case 'checkbox':
        const exValue = this.values[`${index}`].value.concat();
        this.values[`${index}`].value = checked
          ? [...exValue, { value: value, label: label }]
          : exValue.filter(c => c.value !== value);
    }
  }
  // textare の値の更新
  updateInputText(index, text) {
    this.values[`${index}`].value = text;
  }

  // アンケート閉じる
  close() {
    this.$emit('resetEnquete');
  }
  // アンケート送る
  async send() {
    if (!this.valid(this.values)) {
      this.invalidValues = true;
      return;
    }
    try {
      this.$store.commit('ticket/setResetFlag', true);
      const newTicket = await this.updateTicket();
      await this.$ticket.setData(newTicket);
      this.$ticket.post();
      this.done = true;
    } catch (e) {
      console.log(e);
      // Should add error handling
    }
  }
  // 必須事項のvalidation
  valid(values) {
    if (this.requiredIds.length === 0) return true;

    return this.requiredIds.every(i => {
      return this.exist(values[i].value);
    });
  }
  get requiredIds() {
    return this.enquete.filter(e => e.required).map(e => e.index);
  }
  exist(value) {
    const valueType = this.typeOf(value);
    switch (valueType) {
      case 'array':
        return value.length > 0;
      case 'object':
        return Object.keys(value).length > 0;
      default:
        return !!value;
    }
  }
  typeOf(value) {
    var toString = Object.prototype.toString;
    return toString
      .call(value)
      .slice(8, -1)
      .toLowerCase();
  }
  updateTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }

    return this.generateTicket(startTime, query, tagActiveSet);
  }
  generateTicket(startTime, query, tagActiveSet) {
    const commonTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status_enquete: 'done',
    };

    let customTicket = '';
    switch (this.action) {
      case this.$store.state.constObj.ENQUETE.ACTION.SEARCH_FAILED:
        customTicket = { status: 'searchFailed' };
        break;
      case this.$store.state.constObj.ENQUETE.ACTION.SEARCH_NO_SCRIPT:
        customTicket = { status: 'searchNoScript' };
        break;
      case this.$store.state.constObj.ENQUETE.ACTION.FEEDBACK_RESOLVED:
        customTicket = {
          status: 'answered',
          status_feedback: 'done',
          feedback: 'resolved',
        };
        break;
      case this.$store.state.constObj.ENQUETE.ACTION.FEEDBACK_UNRESOLVED:
        customTicket = {
          status: 'answered',
          status_feedback: 'done',
          feedback: 'unresolved',
        };
        break;
      default:
        customTicket = { status: 'answered' };
    }
    const enquete = this.getEnqueteTicketData(this.type, this.values);

    let newTicket = {
      ...commonTicket,
      ...customTicket,
      ...enquete,
    };
    if (this.item) {
      newTicket.items = this.item.items;
    }
    return newTicket;
  }
  getEnqueteTicketData(type, values) {
    const key = `enquete_${type}`;
    return { [key]: values };
  }

  // 文言の初期設定
  fetchConst(type) {
    if (type === this.$store.state.constObj.ENQUETE.TYPE.RESOLVED) {
      this.heading = this.$store.state.constObj.ENQUETE_RESOLVED_TITLE;
      this.message = this.$store.state.constObj.ENQUETE_RESOLVED_MESSAGE;
      return;
    }
    this.heading = this.$store.state.constObj.ENQUETE_UNRESOLVED_TITLE;
    this.message = this.$store.state.constObj.ENQUETE_UNRESOLVED_MESSAGE;
  }

  // 表示アンケートデータの用意
  fetchEnquete(type) {
    const enquete =
      type === this.$store.state.constObj.ENQUETE.TYPE.RESOLVED
        ? this.$store.state.constObj.ENQUETE_SET.RESOLVED
        : this.$store.state.constObj.ENQUETE_SET.UNRESOLVED;
    this.enquete = enquete.sort((a, b) => {
      if (a.index < b.index) return -1;
      if (a.index > b.index) return 1;
      return 0;
    });
  }

  // チケット用アンケートデータの用意
  fetcEnqueteValues() {
    const values = this.enquete.reduce(
      (values, { index, type, title }, idx) => {
        values[`${index}`] = {
          type: type,
          label: title,
          value: this.getInitialValue(type),
          index: Number(index),
        };
        return values;
      },
      {}
    );
    this.values = values;
  }
  getInitialValue(type) {
    switch (type) {
      case 'radio':
        return {};
      case 'checkbox':
        return [];
      default:
        return '';
    }
  }
  setSingleEnquete(enquete) {
    this.singleEnquete = enquete.length === 1;
  }
}
