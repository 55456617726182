import { domReady } from '@';
import { init } from 'ptype/page/main_functions';
import routerConfigPage from 'ptype/page/router';
import FaqClientWrapper from 'product/components/FaqClientWrapper';

routerConfigPage.routes[0].component = FaqClientWrapper;

// Init with customized store and routerConfig
domReady(() => {
  init({ routerConfig: routerConfigPage });
});
